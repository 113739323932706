.custom-spinner .ant-spin-dot-item {
    background-color: red; /* Change the color to your desired color */
  }

.email-div{
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.image-upload-div{
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  

}
.email-and-upload-image-box{
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: space-around;
  align-items: center;

}

.semi-box{
  width: 100%;
  display: flex;
  justify-content: center;
    align-items: center;
}
