body {
  margin: 0;
  font-family: Instrument Sans,sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-spin-dot-item {
  background-color: red;
}

.paper-image{
  width: 150px;
  color: red;
}

.upload-button{
  background-color: red;
  width: 35%;
}

.scanning-note{
  padding-top: 32px;
  color: red;
}



/* Styles for laptops */
@media (min-width: 768px) and (max-width: 1440px) {
.semi-box{
  width: 100%;
}
.inner-box{
  width:70%;
}

}

/* Styles for mobile devices in portrait orientation */
@media (max-width: 767px) {
 .paper-image{
  display: none;
 }
 .semi-box{
  width: 100%;
}
.upload-button{
  width: 70%;
  background-color: red;
  color: red;
}
}

/* Styles for mobile devices in landscape orientation */
@media (max-width: 767px) and (orientation: landscape) {
  .semi-box{
    width: 100%;
  }
  .inner-box{
    width:100%;
  }
}